.loading {
  background: rgba(119, 231, 196, 0.55); 
/* 
  #77e7c4 */
  display: flex;
  align-items: center;
  position: absolute; 
  height: 95vh;
  width: 100vw;
  z-index: 1;
}

.spinner,
.spinner:before,
.spinner:after {
  background-color: rgba(0, 0, 0, 0.5);
  animation: load 1s infinite ease-in-out;
  width: 1em;
  height: 14em;
}
.spinner {
  color: rgba(0, 0, 0, 0.5);
  text-indent: -9999em;
  margin: 20px auto;
  position: relative;
  font-size: 8px;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.spinner:before,
.spinner:after {
  position: absolute;
  top: 0;
  content: '';
}
.spinner:before {
  left: -1.5em;
  animation-delay: -0.32s;
}
.spinner:after {
  left: 1.5em;
}

@keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
